import React from 'react';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import styles from './ShopHero.module.css';

export default function ShopHero() {
  return (
    <Box className={styles.box}>
      <div className={styles.back} />
      <div className={styles.content}>
        <div className={styles.imageContent}>
          <img className={styles.image} src="https://cdn.shopify.com/s/files/1/0598/4482/1049/products/IMG_51732.heic?v=1654597522&width=823" />
        </div>
        <div className={styles.textContent}>
          <h2 className={styles.title}>KnightVision requires a tripod to use</h2>
          <h3 className={styles.title}>No tripod at home? Get started with the <b>KnightVision Starter Package</b></h3>
          <p className={styles.price}>Starting at $49</p>
          <div className={styles.buttons}>
          <a
            style={{ textDecoration: 'none' }}
            href="https://app.knightvision.io/#/shop"
          >
            <Button variant="contained">
              See offers
            </Button>
          </a>
    </div>
        </div>
      </div>
    </Box>
  );
}
