import axios from 'axios';

import gameStore from 'stores/GameStore';
import * as ChessJS from 'chess.js';
const Chess = typeof ChessJS === 'function' ? ChessJS : ChessJS.Chess;

const URI = '/game';

export function fetchSingleGame(gameId) {
  axios.get(`/game/${gameId}/pgn`).then((response) => {
    const pgn = response.data;
    const chess = new Chess();
    chess.load_pgn(pgn);

    gameStore.fen$.next(chess.fen());
    gameStore.pgn$.next(chess.pgn());
  });
}

export function fetchGames(page = 1) {
  axios.get(URI).then((response) => {
    const currentGames = response.data.currentGames;
    const previousGames = response.data.previousGames;
    const publicGames = response.data.publicGames;

    gameStore.gamesList$.next({
      currentGames,
      previousGames,
      publicGames,
    });
  });
}

export function lookAtMove(halvMoveIndex) {
  gameStore.lookingAtMove$.next(halvMoveIndex);
}
