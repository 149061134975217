import React, { useEffect, useState } from 'react';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { orange } from '@mui/material/colors';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import '../frontpage.css';
import { Link, HashRouter } from 'react-router-dom';

import ShopHero from './ShopHero';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function scrollToFaq() {
  const divElement = document.getElementById('faq');
  divElement.scrollIntoView({ behavior: 'smooth' });
}
function scrollToAbout() {
  const divElement = document.getElementById('about');
  divElement.scrollIntoView({ behavior: 'smooth' });
}

const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: 'Raleway',
    },
  },
  palette: {
    primary: {
      main: '#F0AD24',
    },
    secondary: {
      main: '#000000',
    },
  },
});

function FAQBox() {
  return (
    <Box
      id="faq"
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <h2
        style={{
          textAlign: 'center',
          fontSize: '42px',
          marginBottom: '64px',
        }}
      >
        {' '}
        F.A.Q{' '}
      </h2>
      <Stack
        spacing={1}
        sx={{
          maxWidth: '600px',
        }}
      >
        <p
          style={{
            fontSize: '20px',
            fontWeight: 'bold',
          }}
        >
          {' '}
          What tripod do I need to use KnightVision?
        </p>
        <p
          style={{
            fontSize: '18px',
          }}
        >
          {' '}
          You can use any tripod that is able to fold forward, so that the
          camera sees the entire chess board.{' '}
        </p>
        <Divider color="grey" style={{ margin: '1rem 0' }} />
        <p
          style={{
            fontSize: '20px',
            fontWeight: 'bold',
          }}
        >
          {' '}
          Can it save my games to external platforms?
        </p>
        <p
          style={{
            fontSize: '18px',
          }}
        >
          {' '}
          Yes. Currently Lichess is supported, but we will add more platforms
          over time.
        </p>
        <Divider color="grey" style={{ margin: '1rem 0' }} />
        <p
          style={{
            fontSize: '20px',
            fontWeight: 'bold',
          }}
        >
          {' '}
          Can I live stream my chess games using KnightVision?
        </p>
        <p
          style={{
            fontSize: '18px',
          }}
        >
          {' '}
          Yes. You can share a link to your games directly through KnightVision.
        </p>
        <Divider color="grey" style={{ margin: '1rem 0' }} />
        <p
          style={{
            fontSize: '20px',
            fontWeight: 'bold',
          }}
        >
          {' '}
          What platforms are currently supported?
        </p>
        <p
          style={{
            fontSize: '18px',
          }}
        >
          {' '}
          Currently on Android and iOS.{' '}
        </p>
        <Divider color="grey" style={{ margin: '1rem 0' }} />
        <p
          style={{
            fontSize: '20px',
            fontWeight: 'bold',
          }}
        >
          {' '}
          Is it free?
        </p>
        <p
          style={{
            fontSize: '18px',
          }}
        >
          {' '}
          Yes. The core part of KnightVision is free to use, and we intend to
          keep it free.
        </p>
        <Divider color="grey" style={{ margin: '1rem 0' }} />
        <p
          style={{
            fontSize: '20px',
            fontWeight: 'bold',
          }}
        >
          {' '}
          Who created the app?
        </p>
        <p
          style={{
            fontSize: '18px',
            paddingBottom: '100px',
          }}
        >
          {' '}
          We are a team of engineers that work at a Swedish Software company
          called{' '}
          <a href="https://www.northlink.io/" style={{ color: 'white' }}>
            North Link
          </a>
          .
        </p>
      </Stack>
    </Box>
  );
}

function InfoBox() {
  const st = {
    backgroundColor: 'transparent',
  };

  return (
    <Box sx={st}>
      <h1>Save your chess games using only a mobile phone</h1>
      <p id="wrapper">
        <video autoPlay muted loop playsInline id="myVideo">
          <source src="/kv_hero_teaser.webm" type="video/webm" />
          <source src="/kv_hero_teaser.mp4" type="video/mp4" />
        </video>
      </p>
    </Box>
  );
}

function Footer() {
  const elementStyle = {
    paddingRight: 16,
  };

  /*
          <span style={elementStyle}>
            Terms & Conditions
          </span>
          <span style={elementStyle}>
            |
          </span>
          <span style={elementStyle}>
            Privacy Policy
          </span>
          */
  return (
    <Box
      sx={{
        mt: '36px',
        mb: 5,
        fontWeight: '600',
        paddingBottom: '75px',
      }}
    >
      <div id="links" style={{ display: 'flex', justifyContent: 'start' }}>
        <div>
          <span style={elementStyle}>
            <a
              target="_blank"
              href="/eula"
              style={{
                color: 'white',
                textDecoration: 'none',
              }}
            >
              End User License Agreement
            </a>
          </span>
          <span style={elementStyle}>|</span>
          <span style={elementStyle}>
            <a
              target="_blank"
              href="/privacy"
              style={{
                color: 'white',
                textDecoration: 'none',
              }}
            >
              Privacy Policy
            </a>
          </span>
        </div>
        <div style={{ marginLeft: 'auto' }}>
          <span style={elementStyle}>
            <a
              href="https://app.knightvision.io/"
              style={{
                color: 'white',
                textDecoration: 'none',
              }}
            >
              Livestreams
            </a>
          </span>
          <span style={elementStyle}>|</span>
          <span style={elementStyle}>
            <a
              href="https://www.youtube.com/channel/UCdYhYC0xhdYWu0vGdVgma6w"
              style={{
                color: 'white',
                textDecoration: 'none',
              }}
            >
              Youtube
            </a>
          </span>
          <span style={elementStyle}>|</span>
          <span style={elementStyle}>
            <a
              href="https://www.twitch.tv/knightvisionapp"
              style={{
                color: 'white',
                textDecoration: 'none',
              }}
            >
              Twitch
            </a>
          </span>
        </div>
      </div>
    </Box>
  );
}

function Header() {
  return (
    <Box
      sx={{
        mt: '36px',
        mb: 10,
      }}
    >
      <Grid container spacing={2} alignItems="stretch">
        <Grid item xs={9}>
          <img src="/kv_logo.svg" className="headerImage" />
        </Grid>
        <Grid item xs={3}>
          <Stack direction="row" spacing={1} id="header-links">
            <a onClick={scrollToAbout} style={{ cursor: 'pointer' }}>
              {' '}
              About
            </a>
            <a onClick={scrollToFaq} style={{ cursor: 'pointer' }}>
              {' '}
              FAQ{' '}
            </a>
            <a
              href="https://app.knightvision.io/#/shop"
              style={{ color: 'white' }}
            >
              {' '}
              Shop
            </a>
            <a
              href="https://app.knightvision.io/#/login"
              style={{ color: 'white' }}
            >
              {' '}
              Login
            </a>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}

function AboutBox() {
  return (
    <Box id="about">
      <h2
        style={{
          textAlign: 'center',
          fontSize: '42px',
        }}
      >
        {' '}
        About KnightVision{' '}
      </h2>
      <p style={{ fontSize: '28px' }}>
        KnightVision is an application that records and annotates chess moves in
        real time during over-the-board games using only a mobile camera and a
        tripod. Simply mounting the camera next to the chess board and starting
        to play allows you to collect all your over-the-board games in one
        place, complete with annotation and analysis.{' '}
      </p>
      <p style={{ fontSize: '28px' }}>
        KnightVision also supports streaming your games live and has a
        tournament mode. With computer vision, KnightVision is able to annotate
        games across different looking chess boards, and also supports different
        game modes such as chess960.{' '}
      </p>
      <p style={{ fontSize: '28px' }}>
        Whether you play with friends or in a tournament, you will be able to go
        back and explore positions from all your OTB games, no matter the time
        control! With KnightVision, you will never forget a chess game ever
        again.{' '}
      </p>
    </Box>
  );
}

function Apps() {
  return (
    <Box id="apps">
      <Grid container spacing={2} alignItems="stretch">
        <Grid item xs={12} sx={{ display: 'block' }}>
          <Box
            sx={{
              height: '128px',
              display: 'flex',
            }}
          >
            <a href="https://play.google.com/store/apps/details?id=com.knightvision">
              <img
                src="/play-store-button.svg"
                style={{
                  width: '152px',
                  marginTop: '64px',
                  marginRight: '16px',
                }}
              />
            </a>
            <a href="https://apps.apple.com/app/knightvision-chess/id1623336953">
              <img
                src="/app-store-apple.svg"
                style={{
                  width: '152px',
                  marginTop: '10px',
                }}
              />
            </a>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
/*
<Grid item xs={6}>
        <Box sx={{
          height: "57%",
          marginLeft: "-116px",
          marginTop:"64px"
        }}>
        <a href="insert appstore link here">
          <img src="/app-store-apple.svg" style ={{height: "100%"}}/>
        </a>
      </Box>
      </Grid>
*/

function WhyBox() {
  return (
    <Box id="why">
      <h2
        style={{
          textAlign: 'center',
          fontSize: '42px',
          marginTop: '128px',
        }}
      >
        {' '}
        Why KnightVision?{' '}
      </h2>
      <div id="why-container">
        <div id="why-reason">
          <div>
            <img
              src="/pen.svg"
              style={{
                height: '2rem',
                width: '2rem',
                marginRight: '1rem',
              }}
            />
            <p
              style={{
                fontSize: '32px',
                fontWeight: 'bold',
              }}
            >
              {' '}
              Never a game lost{' '}
            </p>
          </div>
          <p
            style={{
              fontSize: '22px',
            }}
          >
            {' '}
            KnightVision records most boards whether you're playing at the cafe
            or in a tournament.{' '}
          </p>
        </div>

        <div id="why-reason">
          <div>
            <img
              src="/cast.svg"
              style={{
                height: '2rem',
                width: '2rem',
                marginRight: '1rem',
              }}
            />
            <p
              style={{
                fontSize: '32px',
                fontWeight: 'bold',
              }}
            >
              {' '}
              Stream matches
            </p>
          </div>
          <p
            style={{
              fontSize: '22px',
            }}
          >
            {' '}
            Easy access to streaming alternatives and all games get saved to
            your profile.{' '}
          </p>
        </div>
        <div id="why-reason">
          <div>
            <img
              src="/checkmark.svg"
              style={{
                height: '2rem',
                width: '2rem',
                marginRight: '1rem',
              }}
            />
            <p
              style={{
                fontSize: '32px',
                fontWeight: 'bold',
              }}
            >
              {' '}
              Ideal for Tournaments{' '}
            </p>
          </div>
          <p
            style={{
              fontSize: '22px',
            }}
          >
            {' '}
            Create events and tournaments through KnightVision. Let other
            players join in and watch their games.{' '}
          </p>
        </div>

        <div id="why-reason">
          <div>
            <img
              src="/people.svg"
              style={{
                height: '2rem',
                width: '2rem',
                marginRight: '1rem',
              }}
            />
            <p
              style={{
                fontSize: '32px',
                fontWeight: 'bold',
              }}
            >
              {' '}
              Social experience{' '}
            </p>
          </div>
          <p
            style={{
              fontSize: '22px',
            }}
          >
            {' '}
            Challenge friends, and explore the latest features in our
            next-generation of Chess gaming.{' '}
          </p>
        </div>
      </div>
    </Box>
  );
}

function FrontPage() {
  return (
    <ThemeProvider theme={theme}>
      <div className="root">
        <Container className="main">
          <Header />
          <Box
            sx={{
              display: 'flex',
            }}
          >
            <div id="main-text">
              <Box className="intro">
                <p className="small-title">Capture & Learn</p>
                <p className="large-title">
                  Make the <span style={{ fontWeight: 'bolder' }}> right </span>{' '}
                  move
                </p>
                <p
                  style={{
                    fontSize: '32px',
                    margin: '12px 0',
                  }}
                >
                  Tired of expensive digital boards? A digital board on your
                  phone. Record, analyze and learn on any board. No hardware, no
                  fuss, just magic
                </p>
                <p
                  style={{
                    fontSize: '32px',
                    margin: '12px 0',
                  }}
                ></p>
                <p
                  style={{
                    fontSize: '32px',
                    margin: '12px 0',
                  }}
                ></p>
                <p style={{ fontSize: '22px', marginTop: '128px' }}>
                  {' '}
                  Easy to set up, simple to use & ideal for tournaments.{' '}
                </p>
                <Apps />
              </Box>
            </div>
            <div id="image-container">
              <img
                src="/kv_web_hero.png"
                style={{
                  height: '100%',
                  width: '100%',
                }}
              />
            </div>
          </Box>
          <Footer />
          <ShopHero />
          <InfoBox />
          <WhyBox />
          <AboutBox />
          <FAQBox />
        </Container>
        <Container></Container>
      </div>
    </ThemeProvider>
  );
}

export default FrontPage;
