import React from 'react';
import * as ChessJS from 'chess.js';
import { lookAtMove } from 'actions/GameActions';
import Paper from '@mui/material/Paper';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

const Chess = typeof ChessJS === 'function' ? ChessJS : ChessJS.Chess;

function MoveHistory(props) {
  const { pgn } = props;
  const chess = new Chess();
  chess.load_pgn(pgn);
  const history = chess.history();

  const previousMoves = [];

  let moveCounter = 1;
  for (let i = 0; i < history.length; i += 2) {
    const wm = history[i];
    let bm = null;
    if (history[i + 1]) {
      bm = history[i + 1];
    }

    const row = (
      <Box key={i} sx={{margin: 2 }}>
        {moveCounter}.<a onClick={() => lookAtMove(i)}>{wm}</a>
        <span> </span>
        <a onClick={() => lookAtMove(i + 1)}>{bm}</a>
      </Box>
    );
    moveCounter += 1;
    previousMoves.push(row);
  }

  return (
    <Box sx={{}}>
      <h2> Moves List </h2>
      {previousMoves}
    </Box>

  );
}

export default MoveHistory;
