import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GameComponent from 'components/GameComponent';
import FrontPage from 'components/FrontPage';
import Login from 'components/Login';
import { fetchGames } from 'actions/GameActions';
import gameStore from 'stores/GameStore';
import { Routes, Route, Link } from 'react-router-dom';

function _getName(game) {
  if (game.white && game.black) {
    return `${game.white} vs ${game.black}`;
  } else if (game.white || game.black) {
    return `${game.white}${game.black}`;
  } else {
    return `${game._id}`;
  }
}
function GameElement(props) {
  const { game } = props;
  const name = _getName(game);
  return (
    <li key={game._id}>
      <Link to={`/game/${game._id}/`}>{name}</Link>
    </li>
  );
}
function GamesList(props) {
  const { games } = props;
  if (!games) {
    return null;
  }

  const elements = props.games.map((game) => {
    return <GameElement game={game} />;
  });
  return <ul>{elements}</ul>;
}

function Main() {
  const [gamesList, setGamesList] = useState({});

  useEffect(() => {
    fetchGames();
    gameStore.data$.subscribe((game) => {
      setGamesList(game.gamesList);
    });
  }, []);

  return (
    <>
      <Header />
      <h1>Knightvision</h1>

      <div>
        <h3>List of my games </h3>
        <GamesList games={gamesList.currentGames} />
      </div>
      <div>
        <h3>List of public games </h3>
        <GamesList games={gamesList.publicGames} />
      </div>
      <div>
        <h3>List of my previous </h3>
        <GamesList games={gamesList.previousGames} />
      </div>
    </>
  );
}

function Header() {
  return (
    <div>
      <Link to={`/public/`}>Public</Link>
      <Link to={`/login`}>Login</Link>
    </div>
  );
}

function App() {
  useEffect(() => {
    toast(
      <>
        <p>We have an important announcement.</p>

        <p>We are shutting down KnightVision in a few weeks. This site will be kept up but the servers and the apps will be shut down.</p>

        <p>It has been a great journey and we are very thankful for all the support we have received. Unfortunately, the costs of running the site are too high and we are unable to continue supporting it.</p>

        <p>The algorithms and the code will be open sourced and available on GitHub. We hope that someone will be able to continue the work we have started.</p>

        <p>Thank you so much for using KnightVision!</p>
      
        <p>Best regards,<br />The KnightVision team<br />2023-05-30</p>
      </>
    );
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<FrontPage />} />
        <Route path="/dashboard" element={<Main />} />
        <Route path="/game/:id/" element={<GameComponent />} />
        <Route path="/login/*" element={<Login />} />
      </Routes>
      <ToastContainer
        autoClose={null}
        position="top-center"
      />
    </>
  );
}

export default App;
