import React from 'react';

function PlayerInfo(props) {
  const { game } = props;

  const headers = game.getHeaders();
  return (
    <div>
      {headers.White} vs {headers.Black}
    </div>
  );
}

export default PlayerInfo;
