import React, { useEffect, useState } from 'react';
import * as ChessJS from 'chess.js';
import gameStore from 'stores/GameStore';
import { lookAtMove } from 'actions/GameActions';
import { useParams } from 'react-router-dom';
import { fetchSingleGame } from 'actions/GameActions';
import { Chessboard } from 'react-chessboard';
import MoveHistory from 'components/MoveHistory';
import GameInfo from 'components/GameInfo';
import PlayerInfo from 'components/PlayerInfo';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

const Chess = typeof ChessJS === 'function' ? ChessJS : ChessJS.Chess;

function _handleKeyNavigation(e, game) {
  const { lookingAtMove } = game;
  const history = game.getHistory();

  switch (e.key) {
    case 'ArrowLeft':
      if (lookingAtMove == null) {
        lookAtMove(history.length - 2);
      } else {
        lookAtMove(lookingAtMove - 1);
      }
      break;
    case 'ArrowRight':
      if (lookingAtMove != null) {
        lookAtMove(lookingAtMove + 1);
      }
      break;
    default:
      break;
  }
}

function GameComponent() {
  const [game, setGame] = useState(null);
  const { id } = useParams();

  const gameRef = React.useRef(game);

  useEffect(() => {
    setInterval(() => {
      fetchSingleGame(id);
    }, 1000);

    gameStore.data$.subscribe((game) => {
      setGame(game);
      gameRef.current = game;
    });

    document.addEventListener('keydown', (e) => {
      _handleKeyNavigation(e, gameRef.current);
    });
  }, [id]);

  if (!game) {
    return null;
  }

  const history = game.getHistory();

  let last = history[history.length - 1];

  let displayFen = game.fen;
  if (game.lookingAtMove !== null) {
    const previousBoard = new Chess();

    for (let i = 0; i <= game.lookingAtMove; i++) {
      previousBoard.move(history[i]);
      last = history[i];
    }
    displayFen = previousBoard.fen();
  }

  let customSquareStyle = {};
  if (last) {
    customSquareStyle[last['from']] = { backgroundColor: 'blue' };
    customSquareStyle[last['to']] = { backgroundColor: 'red' };
  }

  /*
      <h1>A single game {id}</h1>
      <GameInfo pgn={game.pgn} />
      <PlayerInfo game={game} />
      */
  return (
    <Container>
      <h1> Game </h1> 
      <Grid justify="space-between" container>
        <Chessboard
          customSquareStyles={customSquareStyle}
          showNotation={true}
          position={displayFen}
          id="BasicBoard"
        />
        <MoveHistory pgn={game.pgn} />
      </Grid>
    </Container>
  );
}

export default GameComponent;
