import createStore from '../utils/create-store';
import * as ChessJS from 'chess.js';
const Chess = typeof ChessJS === 'function' ? ChessJS : ChessJS.Chess;

const _GameStore = createStore({
  pgn: '',
  fen: '',
  gamesList: {},
  lookingAtMove: null,
  getHistory: function () {
    const chess = new Chess();
    chess.load_pgn(this.pgn);
    return chess.history({ verbose: true });
  },
  getHeaders() {
    const chess = new Chess();
    chess.load_pgn(this.pgn);
    return chess.header();
  },
});

export class GameStore extends _GameStore {}

export default new GameStore();
