import React from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

function LoginUsingEmail() {

  return (
    <div>
      <form method="post">
        <input type="text" name="user" />
        <label htmlFor="user" />

        <input type="password" name="password" />
        <label htmlFor="password">Password</label>
        
        <input type="submit" />
      </form>
    </div>
  );
}

function RegisterNewAccount() {
  return (
    <div>
      <form action="/api/user/register/" method="POST">
        <input type="text" name="user" />
        <label htmlFor="user">Username</label>

        <input type="password" name="password" />
        <label htmlFor="password">Password</label>
        
        <input type="submit" />
      </form>
    </div>
  );
}

function LoginOptions() {
  const theme = createTheme({
    palette: {
      type: 'dark',
      primary: {
        main: '#3f51b5',
      },
      secondary: {
        main: '#f50057',
      },
    },
  });

  const handleSubmit = () => {
    // Handle submit.
  };

  return (
    <ThemeProvider theme={theme}>
    <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign in using Lichess
            </Button>
            <Grid container>
              <Grid item xs>
                <Link to="/" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link to="/" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
/*
*/

function Login() {
  return (
    <>
    <Routes>
      <Route path="/" element={<LoginOptions />} />
      <Route path="/email" element={<LoginUsingEmail />} />
      <Route path="/register" element={<RegisterNewAccount />} />
    </Routes>
    </>
  );
}

export default Login;
